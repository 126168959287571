html, body, #root {
    overflow: hidden;
    margin: 0;
    height: 100%;
}

.recharts-tooltip-wrapper .custom-tooltip {
    background-color: hsla(0,0%,100%,1.0);
    border: 1px solid #f8f8f8;
    line-height: 24px;
    margin: 0;
    padding: 10px;
    width: 280px;
    font-size: smaller;
}

.recharts-tooltip-wrapper .custom-tooltip .label {
    color: #666;
    font-weight: 700;
    margin: 0
}

.recharts-tooltip-wrapper .custom-tooltip .desc {
    color: #555;
    margin: 0
}

.recharts-tooltip-wrapper .custom-tooltip .intro {
    border-top: 1px solid #f5f5f5;
    margin: 0
}

g&:focus, path&:focus {
    outline: none !important;
}

.recharts-layer {
    outline: none !important;
}

.recharts-wrapper>svg>g {
    outline: none !important;
}